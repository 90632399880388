import { ApiService } from "./api-service";

interface Translation {
  profile: string;
  passwordreset: string;
  feedback: string;
  feedbackhref: string;
  datenschutzbedingungen: string;
  datenschutzbedingungenhref: string;
  logout: string;
  mieterdossier: string;
  mieterinformationen: string;
  inactive: string;
}

export class LanguageService {
  private translation: Translation;
  private currentLanguage: string;
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public setLanguage(language) {
    this.currentLanguage = language;
    this.load(language).then(() => {
      document.getElementById("profile").innerText = this.translation.profile;
      document.getElementById(
        "password-reset"
      ).innerText = this.translation.passwordreset;
      document.getElementById("feedback").innerText = this.translation.feedback;
      document
        .getElementById("feedback-link")
        .setAttribute("href", this.translation.feedbackhref);

      document.getElementById(
        "privacy"
      ).innerText = this.translation.datenschutzbedingungen;
      document
        .getElementById("privacy-link")
        .setAttribute("href", this.translation.datenschutzbedingungenhref);

      document.getElementById("logout").innerText = this.translation.logout;
      document.getElementById("currentLanguage").innerText = language;
      document.getElementById(
        "if-not-active"
      ).innerHTML = this.translation.inactive;
      Array.from(
        document.getElementsByClassName("translation-mieterdossier")
      ).forEach((el) => {
        el.innerHTML = this.translation.mieterdossier;
      });
      Array.from(
        document.getElementsByClassName("translation-mieterinformationen")
      ).forEach((el) => {
        el.innerHTML = this.translation.mieterinformationen;
      });
    });
  }

  public getLanguage() {
    if (this.currentLanguage) {
      return this.currentLanguage;
    }

    const browserLanguage = navigator.language.substring(0, 2);
    return browserLanguage;
  }

  public changeLanguage(language) {
    this.apiService.get("/language/" + language);
    this.setLanguage(language.toLowerCase());
  }

  async load(language) {
    return fetch("/assets/" + language + ".json")
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.translation = json as Translation;
      })
      .catch((err) => console.error(err));
  }
}
