import { registerApplication, start, navigateToUrl } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import { AuthService } from "./auth-service";
import { LanguageService } from "./language-service";
import { AppConfigService } from "./app-config.service";
import { ApiService } from "./api-service";
import { Language } from "./language";

const data = {
  loaders: {
    appLoader: `<div class="loading">
                  <div class="spinner">
                    <div class="spinner-dot"></div>
                    <div class="spinner-dot"></div>
                    <div class="spinner-dot"></div>
                    <div class="spinner-dot"></div>
                    <div class="spinner-dot"></div>
                    <div class="spinner-dot"></div>
                  </div>
                </div>`,
  },
  props: {
    user: undefined,
  },
};

const appConfigService = new AppConfigService();
const apiService = new ApiService(appConfigService);
const languageService = new LanguageService(apiService);
const authService = new AuthService(
  languageService,
  appConfigService,
  apiService
);

authService.init().then(() => {
  document.getElementById("loading").style.display = "flex";
  authService.loadAccount().then((user) => {
    data.props.user = user;

    const routes = constructRoutes(
      document.querySelector("#single-spa-layout") as HTMLTemplateElement,
      data
    );
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return System.import(name);
      },
    });
    const layoutEngine = constructLayoutEngine({ routes, applications });

    applications.forEach((app) => {
      if (user.isActive) {
        registerApplication(app);
      }
    });
    layoutEngine.activate();
    start();

    Array.from(document.getElementsByClassName("if-active")).forEach((el) => {
      (<HTMLElement>el).style.display = user.isActive ? "flex" : "none";
    });

    document.getElementById("if-not-active").style.display = user.isActive
      ? "none"
      : "block";

    document.getElementById("loading").style.display = "none";
    languageService.setLanguage(Language[user.language]);
    document.getElementById("displayName").innerHTML = user.username;

    document.getElementById("logout").onclick = function () {
      authService.logout();
    };
    document.getElementById("profile").onclick = function () {
      authService.showProfile();
    };
    document.getElementById("password-reset").onclick = function () {
      authService.startResetPasswordFlow();
    };
    document.getElementById("btn-de").onclick = function () {
      languageService.changeLanguage("De");
      data.props.user.language = 0;
      location.reload();
    };
    document.getElementById("btn-fr").onclick = function () {
      languageService.changeLanguage("Fr");
      data.props.user.language = 1;
      location.reload();
    };
    document.getElementById("btn-it").onclick = function () {
      languageService.changeLanguage("It");
      data.props.user.language = 2;
      location.reload();
    };
    document.getElementById("btn-en").onclick = function () {
      languageService.changeLanguage("En");
      data.props.user.language = 3;
      location.reload();
    };
  });
});
