import { AppConfigService } from "./app-config.service";

export class ApiService {
  private token: string;
  private appConfig: AppConfigService;

  constructor(appConfig: AppConfigService) {
    this.appConfig = appConfig;
  }

  public setToken(token) {
    this.token = token;
  }

  public get(url): Promise<Response> {
    var headers = new Headers();
    var bearer = "Bearer " + this.token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      this.appConfig.get().api.baseUrl +
        url +
        "?code=" +
        this.appConfig.get().api.authCode,
      options
    );
  }
}
