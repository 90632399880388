interface Config {
  api: {
    baseUrl: string;
    authCode: string;
  };
  auth: {
    clientId: string;
    loginAuthority: string;
    resetAuthority: string;
    changeAuthority: string;
    generalScopes: string[];
    apiScopes: string[];
    redirectUri: string;
  };
}

export class AppConfigService {
  private appConfig: Config;

  load() {
    return fetch("/assets/config.json")
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.appConfig = json as Config;
      })
      .catch((err) => console.error(err));
  }

  get() {
    return this.appConfig;
  }
}
