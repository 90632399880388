import * as Msal from "msal";
import { AuthError, AuthResponse, Logger, UserAgentApplication } from "msal";
import { navigateToUrl } from "single-spa";
import { ApiService } from "./api-service";
import { AppConfigService } from "./app-config.service";
import { Language } from "./language";
import { LanguageService } from "./language-service";

interface User {
  username: string;
  language: string;
  tenantOid: string;
  isActive: boolean;
}

export class AuthService {
  msalInstance: any;
  appConfig: AppConfigService;
  apiService: ApiService;

  languageService: LanguageService;

  constructor(
    languageService: LanguageService,
    appConfig: AppConfigService,
    apiService: ApiService
  ) {
    this.languageService = languageService;
    this.appConfig = appConfig;
    this.apiService = apiService;
  }

  public init(): Promise<void> {
    return this.appConfig.load().then(() => {
      const msalConfig = {
        auth: {
          clientId: this.appConfig.get().auth.clientId,
          authority: this.appConfig.get().auth.loginAuthority,
          validateAuthority: false,
          redirectUri: this.appConfig.get().auth.redirectUri,
          navigateToLoginRequestUrl: true,
        },
      };

      this.msalInstance = new Msal.UserAgentApplication(msalConfig);

      this.msalInstance.handleRedirectCallback((error, response) => {
        // handle redirect response or error
        console.log(response);
        console.log(error);
        if (error) {
          this.handleAuthError(error);
        }
      });
    });
  }

  public loadAccount(): Promise<User> {
    // if the user is already logged in you can acquire a token
    if (this.msalInstance.getAccount()) {
      const tokenRequest = {
        scopes: this.appConfig.get().auth.apiScopes,
      };
      return this.msalInstance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          return this.setAccount(response);
        })
        .catch((err) => {
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          return this.msalInstance
            .acquireTokenRedirect(tokenRequest)
            .then((response) => {
              return this.setAccount(response);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    } else {
      // user is not logged in, you will need to log them in to acquire a token
      this.msalInstance.loginRedirect({
        extraQueryParameters: {
          ui_locales: this.languageService.getLanguage(),
        },
      });
    }
  }

  public showProfile() {
    // Clear cache on profile change
    this.msalInstance
      .acquireTokenSilent({ scopes: this.appConfig.get().auth.apiScopes })
      .then((response: AuthResponse) => {
        this.msalInstance.clearCacheForScope(response.accessToken);
      });

    const profileEditService = new UserAgentApplication({
      auth: {
        clientId: this.appConfig.get().auth.clientId,
        authority: this.appConfig.get().auth.changeAuthority,
        validateAuthority: false,
        redirectUri: this.appConfig.get().auth.redirectUri,
        navigateToLoginRequestUrl: true,
      },
    });

    profileEditService.handleRedirectCallback(
      (authErr: AuthError, response?: AuthResponse) => {
        if (authErr === null) {
          console.log("Profile Editing Successful");
        } else {
          console.log(authErr);
        }
      }
    );

    profileEditService.loginRedirect({
      extraQueryParameters: { ui_locales: this.languageService.getLanguage() },
    });
  }

  public logout() {
    this.msalInstance.logout();
  }

  public startResetPasswordFlow() {
    const resetAuthService = new UserAgentApplication({
      auth: {
        clientId: this.appConfig.get().auth.clientId,
        authority: this.appConfig.get().auth.resetAuthority,
        validateAuthority: false,
        redirectUri: this.appConfig.get().auth.redirectUri,
        navigateToLoginRequestUrl: true,
      },
    });

    resetAuthService.handleRedirectCallback(
      (authErr: AuthError, response?: AuthResponse) => {
        if (authErr === null) {
          console.log("Reset Password Successful");
        } else {
          console.log(authErr);
        }

        this.msalInstance.loginRedirect({
          extraQueryParameters: {
            ui_locales: this.languageService.getLanguage(),
          },
        });
      }
    );

    resetAuthService.loginRedirect({
      extraQueryParameters: { ui_locales: this.languageService.getLanguage() },
    });
  }

  private handleAuthError(authError: AuthError) {
    //password reset
    if (
      authError.errorMessage &&
      authError.errorMessage.indexOf("AADB2C90118") !== -1
    ) {
      this.startResetPasswordFlow();
    }
    // User cancel auth
    else if (
      authError.errorMessage &&
      authError.errorMessage.indexOf("AADB2C90091") !== -1
    ) {
      this.goBack();
      // Popup Error
    } else if (
      authError.errorCode === "popup_window_error" &&
      authError.errorMessage &&
      authError.errorMessage.startsWith("Error opening popup window")
    ) {
      this.msalInstance.loginRedirect();
      // User login error
    } else if (
      authError.errorCode === "user_login_error" &&
      authError.errorMessage &&
      authError.errorMessage.startsWith("User login is required")
    ) {
      this.msalInstance.loginRedirect();
      // User cancel popup
    } else if (authError.errorCode === "user_cancelled") {
      this.msalInstance.loginRedirect();
    }
  }

  private setAccount(response): Promise<User> {
    this.apiService.setToken(response.accessToken);
    return this.apiService
      .get("/user/" + this.languageService.getLanguage())
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        return {
          username: response.displayName,
          language: response.user.language,
          tenantOid: response.user.externalSystemId,
          isActive: response.user.isActive,
        };
      });
  }

  private goBack(): void {
    window.history.back();
  }
}
